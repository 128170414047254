import { template as template_a3f8dd489e91422ea09d8a5dfbd2b0d4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_a3f8dd489e91422ea09d8a5dfbd2b0d4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
